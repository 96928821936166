/* eslint-disable @next/next/no-page-custom-font */
import { NextPage } from "next";
import dynamic from "next/dynamic";
import Head from "next/head";

const SpaceComponent = dynamic(
  () => import("components/PublicPages/Space/Space"),
  {
    ssr: false,
  }
);

const Space: NextPage = () => (
  <>
    <Head>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@500;700;800;900&family=Montserrat:wght@500&family=Roboto+Slab:wght@700&display=swap"
        rel="stylesheet"
      />
    </Head>
    <SpaceComponent />
  </>
);

export default Space;
